#tour-virtual{
    .embed-responsive-vt{
        width: 100%;
        height: 550px;
        .embed-responsive-item-vt{
            width: 100%;
            height: 100%;
            border:0;
        }
    }
}